import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import ecStat from 'echarts-stat';
import useLoadData from '../../../data/swr/useLoadData';
import RequestLoading from '../../utils/request/RequestLoading';
import RequestError from '../../utils/request/RequestError';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';

echarts.registerTransform(ecStat.transform.regression);

const Charts = (props) => {
  const tec = props.tec ?? '';
  const time = props.time ?? '';
  const temp = props.temp ?? '';
  const irrad = props.irrad ?? '';
  const theme = localStorage.getItem('data-bs-theme') || 'light';

  const [predict, setPredict] = useState([]);
  const [chartsDataset, setChartsDataset] = useState([]);
  const [chartsSeries, setChartsSeries] = useState([]);

  const { data, error, isLoading } = useLoadData(`/predict?tec=${tec}&time=${time}&temp=${temp}&irrad=${irrad}`);
  // const { data1, error1, isLoading1 } = useLoadData(`/predict-sample?date=2015-08-15`);
  // const { data2, error2, isLoading2 } = useLoadData(`/predict-sample?get=data`);
  useEffect(() => {
    if (data) {
      setPredict(data.predict ?? []);
      if (data?.predict?.length) {
        const series = [];
        const source = [];
        const hours = ['hours'];
        data.predict.map((tec, index) => {
          const preds = [tec.acronym];
          const predict = tec.predict ?? [];
          predict.map((pre) => {
            if (index === 0) hours.push(pre.hour);
            preds.push(pre.pred);
            return true;
          });
          if (index === 0) source.push(hours);
          source.push(preds);

          series.push({
            type: 'line',
            smooth: true,
            seriesLayoutBy: 'row',
            emphasis: { focus: 'series' },
            itemStyle: {
              color: tec.color ?? '#ff7f50',
            },
          });

          return true;
        });

        series.push({
          type: 'pie',
          id: 'pie',
          radius: '30%',
          center: ['50%', '25%'],
          emphasis: {
            focus: 'self',
          },
          label: {
            formatter: '{b}: {@2012} ({d}%)',
          },
        });
        setChartsDataset([...source]);
        setChartsSeries([...series]);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  if (isLoading) return <RequestLoading />;
  if (error)
    return (
      <Card className="h-100">
        <Card.Body>
          <RequestError error={error} />
        </Card.Body>
      </Card>
    );
  return (
    <>
      <Row>
        {predict.map((tec) => {
          const hours = [];
          const preds = [];
          const max = tec.predict.length;
          const med = parseInt(Number(max) / 2);

          const source = [];
          const piedata = [];

          tec.predict.map((p, index) => {
            if (index === 0 || index === max - 1 || index === med) hours.push(p.hour);
            if (index === 0 || index === max - 1 || index === med) preds.push(p.pred);
            source.push([index, Number(p.pred)]);
            piedata.push({ value: Number(p.pred), name: p.hour });
            return true;
          });

          return (
            <Col key={tec.key} lg="4">
              <Card className="mb-3">
                <Card.Header>
                  <h5 className="m-0">
                    <span className={`text-${tec.class} fw-bold me-1`}>{tec.acronym}</span>- {tec.name}
                  </h5>
                </Card.Header>
                <Card.Body className="bg-light pt-0">
                  <Tabs defaultActiveKey={`line-${tec.key}`} transition={false} id="tab-charts" className="mb-3- justify-content-end">
                    <Tab eventKey={`line-${tec.key}`} title={<span className="fas fa-chart-line"></span>}>
                      <ReactECharts
                        style={{ height: '400px' }}
                        theme={theme}
                        option={{
                          xAxis: {
                            type: 'category',
                            data: hours,
                          },
                          yAxis: {
                            type: 'value',
                          },
                          series: [
                            {
                              data: preds,
                              type: 'line',
                              smooth: true,
                            },
                          ],
                          tooltip: {
                            trigger: 'axis',
                            showContent: false,
                            axisPointer: {
                              type: 'cross',
                              label: {
                                backgroundColor: '#6a7985',
                              },
                            },
                          },
                        }}
                      />
                    </Tab>
                    <Tab eventKey={`regression-${tec.key}`} title={<span className="fas fa-chart-area"></span>}>
                      <ReactECharts
                        style={{ height: '400px' }}
                        theme={theme}
                        option={{
                          dataset: [
                            {
                              source: source,
                            },
                            {
                              transform: {
                                type: 'ecStat:regression',
                                config: {
                                  method: 'exponential',
                                  // 'end' by default
                                  // formulaOn: 'start'
                                },
                              },
                            },
                          ],
                          tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                              type: 'cross',
                            },
                          },
                          xAxis: {
                            splitLine: {
                              lineStyle: {
                                type: 'dashed',
                              },
                            },
                          },
                          yAxis: {
                            splitLine: {
                              lineStyle: {
                                type: 'dashed',
                              },
                            },
                          },
                          series: [
                            {
                              name: 'scatter',
                              type: 'scatter',
                              datasetIndex: 0,
                            },
                            {
                              name: 'line',
                              type: 'line',
                              smooth: true,
                              datasetIndex: 1,
                              symbolSize: 0.1,
                              symbol: 'circle',
                              label: { show: true, fontSize: 16 },
                              labelLayout: { dx: -20 },
                              encode: { label: 2, tooltip: 1 },
                            },
                          ],
                        }}
                      />
                    </Tab>
                    <Tab eventKey={`pie-${tec.key}`} title={<span className="fas fa-chart-pie"></span>}>
                      <ReactECharts
                        style={{ height: '400px' }}
                        theme={theme}
                        option={{
                          tooltip: {
                            trigger: 'item',
                          },
                          legend: {
                            orient: 'vertical',
                            left: 'left',
                          },
                          series: [
                            {
                              name: 'Access From',
                              type: 'pie',
                              radius: '50%',
                              data: piedata,
                              emphasis: {
                                itemStyle: {
                                  shadowBlur: 10,
                                  shadowOffsetX: 0,
                                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                                },
                              },
                            },
                          ],
                        }}
                      />
                    </Tab>
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
        <Col lg="12">
          <Card className="mb-3">
            <Card.Header>
              <h5 className="m-0">Comparativo</h5>
            </Card.Header>
            <Card.Body className="bg-light">
              <ReactECharts
                style={{ height: '500px' }}
                theme={theme}
                option={{
                  legend: {},
                  tooltip: {
                    trigger: 'axis',
                    showContent: false,
                    axisPointer: {
                      type: 'cross',
                      label: {
                        backgroundColor: '#6a7985',
                      },
                    },
                  },
                  dataset: {
                    source: chartsDataset,
                  },
                  xAxis: { type: 'category' },
                  yAxis: { gridIndex: 0 },
                  grid: { top: '55%' },
                  series: chartsSeries,
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Charts;
