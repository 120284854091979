import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, ButtonGroup, Row, Col } from 'react-bootstrap';
import useLoadData from '../../../data/swr/useLoadData';
import RequestLoading from '../../utils/request/RequestLoading';
import RequestError from '../../utils/request/RequestError';

const NavTecs = (props) => {
  const [show, setShow] = useState(false);
  const [tec, setTec] = useState({});

  const [tecs, setTecs] = useState([]);
  const { data, error, isLoading } = useLoadData('/technologies');

  useEffect(() => {
    if (data) setTecs(data.technologies ?? []);
  }, [data]);

  if (isLoading) return <RequestLoading />;
  if (error) return <RequestError error={error} />;
  return (
    <>
      <Card>
        <Card.Header>
          <h5 className="m-0">Tecnologias</h5>
          <small>Navegue pelas tecnologias</small>
        </Card.Header>
        <Card.Body className="bg-light">
          <Card.Text>
            {tecs.map((t) => {
              if (t.key)
                return (
                  <ButtonGroup key={t.acronym} className="w-100">
                    <Button
                      variant="false"
                      className={`btn btn-falcon-${t.class} d-block w-100 mb-3 fw-bold ${props?.tec === t.key ? 'active' : ''}`}
                      onClick={() => props.setTec(t.key)}
                    >
                      {t.acronym}
                      <small className="d-block text-muted">{t.name}</small>
                    </Button>
                    <Button
                      title="Informações da Tecnologia"
                      variant="false"
                      className="btn btn-falcon-default mb-3 fw-bold"
                      onClick={() => {
                        setShow(true);
                        return setTec(t);
                      }}
                    >
                      <i className="fas fa-question-circle"></i>
                    </Button>
                  </ButtonGroup>
                );
              return '';
            })}
            <Button variant="false" className="btn btn-outline-primary d-block w-100 mb-3 fw-bold" onClick={() => props.setTec('')}>
              Todas
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
      <Modal
        show={show}
        fullscreen={false}
        dialogClassName="modal-90w"
        onHide={() => {
          setShow(false);
          return setTec({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {tec.acronym} - {tec.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {tec.articles &&
              tec.articles.length &&
              tec.articles.map((article, idx) => {
                return (
                  <Col className="mt-4" key={tec.key + idx} lg={idx === 0 || idx === 1 ? '6' : '12'}>
                    {/* {article.title && <h4 className="mt-0 mb-3">{article.title}</h4>} */}
                    {article.image && <img alt={article.title} className="img-thumbnail w-100" src={`/static/images/${article.image}`} />}
                  </Col>
                );
              })}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavTecs;
